:root {
  --yellow: #f5e74c;
}
$white: #fff !default;
$dark-bg: #282828 !default;
$text-muted: rgb(108, 117, 125);
$light-gray: #808080 !default;
$gray: #8898aa !default;
$dark-gray: #606c89 !default;
$light-bg: #f6f6f6 !default;
$blur-color: rgba(68, 68, 68, 0.5);
$placeholder-color: #c0c0c0;
$orange: #f67109;
$red: red;
$dark: #202327;

$yellow: #f6e709 !default;
$light-yellow: #fffaad !default;
$parrot-green: #91f533 !default;
$green: #00bb2c !default;
$hoverYellow: #f3e11c !default;
$yellow-blur: #ffed2583 !default;
$white-space: nowrap !default;
$btn-hover: #f8ef87 !default;
$light-white: #f9f9f9 !default;
$off-white: #f8f9fe;
$admin-dark-bg: linear-gradient(180deg, #212831 0%, #14181d 100%);

$black: #444444 !default;
$button-box-shadow: rgba(240, 191, 54, 0.3);
$card-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.2) !important;
$content-bg: rgba(231, 231, 231, 0.85) !important;
$bg-opacity: 1 !important;
$border: 1px solid #f5e74c !default;
$border-color: #f5e74c !default;
$bold-border: 3px solid #f5e74c !default;

$border-radius-6: 6px !default;
$border-radius-10: 10px !default;
$border-radius-14: 14px !default;
$border-radius-20: 20px !default;
$border-radius-24: 24px !default;

$text-uppercase: uppercase !default;
$font-10: 10px !default;
$font-12: 12px !default;
$font-14: 14px !default;
$font-16: 16px !default;
$font-18: 18px !default;
$font-20: 20px !default;
$font-22: 22px !default;
$font-24: 24px !default;
$font-26: 26px !default;
$font-28: 28px !default;
$font-32: 32px !default;
$font-36: 36px !default;
$font-40: 40px !default;

$font-weight-300: 300 !default;
$font-weight-400: 400 !default;
$font-weight-500: 500 !default;
$font-weight-600: 600 !default;
$font-weight-700: 700 !default;
$font-weight-800: 800 !default;
