@import "./variables.scss";

@mixin bgProperties {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgProperties {
  @include bgProperties;
}

.bgBlur {
  background-color: $blur-color !important;
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}
.customBgDark {
  background: $dark;
}

.font14 {
  font-size: $font-14 !important;
}

.w-20 {
  width: 20% !important;
}

.middle-subscription-card {
  transition: transform 0.3s ease;
  transform: scale(1.1);
  z-index: 1;
}

.custom-badge {
  font-size: 14px !important;
  background-color: $yellow !important;
  border-radius: 14px !important;
}

.textYellow {
  color: $yellow !important;
}

.textOrange {
  color: $orange !important;
}

.textGrey {
  color: $text-muted !important;
}

.textParrotGreen {
  color: $parrot-green;
}


.contentCard {
  margin-top: 12vh;
  overflow-y: scroll;
  height: 88vh !important;
  border:none !important;
}

.contentCardPadding {
  padding-top: 12vh;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.cursorPointer {
  cursor: pointer;
}

.BorderRadius {
  box-shadow: $card-box-shadow;
  border: none !important;
}
.customDropdownRadius,
.BorderYellow,
.onlyBorderRadius,
.BorderRadius,
.subscriptionDiv {
  border-radius: 14px !important;
}

.ImgBorder {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}
.yellowBorder,
.BorderYellow {
  border: 1px solid $yellow !important;
}

a,
.a {
  text-decoration: none !important;
  outline: none !important;
}

Input {
  &:focus {
    border: 1px solid $yellow !important;
    outline: 1px solid $yellow !important;
  }
}

.profileEditIcon {
  &:hover {
    fill: $yellow;
  }
}

Input::-webkit-input-placeholder {
  color: $placeholder-color;
}
.dropdown-menu {
  padding: 3px 3px !important;
}
.dropdown-item {
  &:active,
  &:hover,
  &:focus,
  &::selection {
    background-color: $yellow;
    color: $black;
    outline: none;
    border-radius: 6px;
  }
}

.react-tel-input,
.genderBtn,
.paymentMethodBtn,
.select,
.form-control,
.form-control-lg {
  &:focus,
  &:focus-visible {
    border: 1px solid $parrot-green !important;
    box-shadow: none !important;
    outline: none !important;
  }
}
.react-tel-input {
  border-radius: 14px;
  .form-control-lg {
    border-radius: 14px !important;
    border: 1px solid #DEE2E6;
    padding-left: 50px !important;
    &:focus {
      outline: none !important;
    }
  }
  .flag-dropdown {
    background-color: $content-bg !important;
    border-top-left-radius: 14px !important;
    border-bottom-left-radius: 14px !important;
    border: none !important;
    .selected-flag {
      border-top-left-radius: 14px !important;
      border-bottom-left-radius: 14px !important;
      &:hover {
        border-top-left-radius: 14px !important;
        border-bottom-left-radius: 14px !important;
      }
    }
  }
}

.form-check-input:checked::before {
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\2713 ";
  color: $parrot-green !important;
  font-weight: bold;
  margin-top: -13px;
  margin-left: 1px;
}

.form-check-input:checked {
  &focus {
    box-shadow: none !important;
    background-color: $yellow-blur !important;
    color: $parrot-green !important;
  }
  background-color: $yellow-blur !important;
  border: 1px solid $parrot-green !important;
  color: $parrot-green !important;
}

.buttonBoxShadow {
  box-shadow: 0px 2px 14px 0px $button-box-shadow;
}

.errorField {
  margin-bottom: 0 !important;
  color: $orange;
  font-size: $font-14;
}

.customPhoneInput {
  font-size: $font-14 !important;
}

.customPaddingY {
  padding: 12px 0px !important;
}

.customSpacing {
  letter-spacing: 1px;
  line-height: 24px;
}

.customDropDown {
  font-size: $font-14 !important;
  box-shadow: none !important;
}
.left-comment {
  text-align: left !important;
}

.right-comment {
  text-align: right !important;
}
.CategoryCard {
  .CategoryCardHeader {
    background-color: $light-yellow !important;
    height: 100% !important;
  }
}
.UncontrolledDropdown {
  .DropdownMenu {
    .DropdownItem {
      padding: 5px;
      &:hover,
      &:active {
        background: $yellow;
      }
    }
  }
}
.CameraImg {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 99;
  border-radius: 100%;
  right: 0;
  bottom: 6px;
  border: 2px solid white;
  background-color: rgba(24, 24, 24, 0.99);
}

.tableBodyWrapperPagination {
  max-height: 68vh;
  overflow-y: scroll;
}

.genderBtn {
  .selected {
    color: $parrot-green !important;
    border: 2px solid $parrot-green !important ;
  }
}

.paymentMethodBtn {
  .selected {
    color: $parrot-green !important;
    border: 2px solid $parrot-green !important ;
  }
}

.rmsc {
  .dropdown-container {
    padding: 8px 10px !important;
    border-radius: 14px !important;
    font-size: 14px !important;
    &:focus-within {
      border: 1px solid $parrot-green !important;
      box-shadow: none !important;
    }
  }
}
.arabicBorderHover,
.borderHover {
  position: relative;
  overflow: hidden;
}
.arabicBorderHover::before,
.borderHover::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: $yellow;
  transition: width 0.3s ease;
}
.arabicBorderHover::before {
  margin-bottom: 0px;
}
.arabicBorderHover:hover::before,
.borderHover:hover::before {
  width: 100%;
}

.currentlyWorkingBtn {
  .selected {
    color: $parrot-green !important;
    border: 2px solid $parrot-green !important ;
  }
}
.selectField {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.text-black-custom {
  color: $black !important;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.cursorPointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: transparent !important;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.remove-arrow {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow::-ms-clear {
  display: none;
}

.shimmer-thumbnail {
  height: 38vh !important;
  border-radius: 14px;
}

.signInCol {
  background-color: white;
  }
  
  @media (max-width: 767px) {
    /* Apply background image only when the screen size is less than md (767px) */
    .signInCol {
    background: transparent !important;;
    }
    .customPadding{
      padding-top: 58px !important;
      padding-bottom: 58px !important;
    }
  }