@import "../../Assets/GlobalStyle.scss";
@import "../../../../Assets/Scss/variables.scss";
.containerAfter{
    position: relative;
    &::after{
        content:"";
        top:0;
        left: 0;
        right: 0;
        position: absolute;
        background-image: url("../../../../Assets/img/bg2.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        width: 100%;
        z-index: 1;
    }
}