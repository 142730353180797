@import "./Assets/Scss/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@200&family=Roboto:wght@100&display=swap');

* {
  font-family: 'Noto Sans Arabic', sans-serif;
}
.owl-next {
  position: absolute !important;
  display: inline !important;
  right: 0 !important;
}

.owl-prev {
  position: absolute !important;
  display: inline !important;
  left: 0 !important;
}

.owl-prev span {
  
}

.owl-next span {
  
}

.owl-nav {
  position: absolute;
  bottom: -70px;
  width: 100%;
}

.owl-carousel .owl-item img {
  width: 80px !important;
}

/*navbar styling*/
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: 0 !important;
  content: "\276E" !important;
  color: var(--yellow) !important;
  border: none !important;
  transform: rotate(270deg);
  font-size: 20px;
}

.bg-white-custom {
  background-color: #fff !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
}

.navbar {
  height: 11vh;
 
}

.nav-link {
  display: flex !important;
  cursor: pointer;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.dropdown-menu.show {
  padding: 6px;
}

.dropdown-item {
  display: flex !important;
  gap: 15px;
  align-items: center;
}
/* Portfolio Section */
#portfolio {
  padding: 100px 0;
}
.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(99, 114, 255, 0.8) 0%,
    rgba(92, 169, 251, 0.8) 100%
  );
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}

/* Gallery.css */

/* Styling for the photo album wrapper */
.photo-album-wrapper {
  display: flex;
  flex-wrap: wrap;
}

/* Styling for each photo card */
.photo-card {
  position: relative;
  overflow: hidden;
  margin: 10px;
}

/* Styling for the overlay */
.overlayAbc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Change the color and opacity as needed */
  transition: opacity 0.3s ease-in-out;
}

/* Hover effect to show the overlay */
.photo-card:hover .overlayAbc {
  opacity: 1;
}
